import React from 'react';
import classnames from 'classnames';

import StaticImage from './StaticImage';
import { postType } from './types';
import { stripHtml, isTodayOrFuture, localeFormat } from './utils';

export default function Post({ data }) {
  const hasImage = Boolean(data.post_image);
  const dates = data.dates
    ? data.dates.filter(date => isTodayOrFuture(new Date(date)))
    : [];
  const hasDates = Boolean(dates.length);
  const closestDate = hasDates ? new Date(dates[0]) : null;

  const postIsEvent = Boolean(data.posttype === 'iucnorr_event');
  const eventIsPassed = postIsEvent && !hasDates;

  const postClasses = classnames(
    'search-list-item',
    { 'search-item--has-image': hasImage },
    { 'search-item--no-image': !hasImage },
    { 'search-item--has-date': hasDates }
  );

  return (
    <div className={postClasses}>
      {hasDates && (
        <div className="search-item-circle post-block-date">
          <div className="dates">
            {(dates.length > 1 && (
              <div>
                <span className="date-text">
                  {window.IUCNORR_THEME_DATA.text.severalDates}
                </span>
              </div>
            )) || (
              <div>
                <span className="date-day">
                  {localeFormat(closestDate, { day: 'numeric' })}
                </span>
                <span className="date-month">
                  {localeFormat(closestDate, { month: 'short' })}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {eventIsPassed && (
        <div className="search-item-circle search-item-circle--passed post-block-date">
          <div className="dates">
            <span className="date-text">
              {window.IUCNORR_THEME_DATA.text.passedEvent}
            </span>
          </div>
        </div>
      )}
      <div className="search-card clearfix">
        {hasImage && (
          <div className="search-item-image">
            <a href={data.post_url} tabIndex="-1" aria-hidden="true">
              <StaticImage
                url={data.post_image}
                alt={stripHtml(data.post_title)}
              />
            </a>
          </div>
        )}
        <div className="search-item-text">
          <div className="search-item-titles">
            <h4 className="search-item-title">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a
                href={data.post_url}
                dangerouslySetInnerHTML={{ __html: data.post_title }}
              />
            </h4>
            {data.post_excerpt && (
              <p
                className="search-item-excerpt"
                dangerouslySetInnerHTML={{ __html: data.post_excerpt }}
              />
            )}
          </div>
          <div className="search-item-contacts">
            {data.post_url && (
              <a
                className="search-item-link"
                href={data.post_url}
                tabIndex="-1"
              >
                {data.pretty_link}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
Post.displayName = 'Post';
Post.propTypes = {
  data: postType.isRequired,
};
