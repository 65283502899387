import React from 'react';
import classnames from 'classnames';

import PersonList from './PersonList';
import TagList from './TagList';
import PostList from './PostList';
import { searchResultsType } from './types';

export default function SearchResults(props) {
  const resultsClasses = classnames(
    'search-results',
    'wrap-extra-wide',
    { 'modal--has-results': !!props.data },
    { 'modal--has-persons': !!props.data.persons },
    { 'modal--has-tags': !!props.data.tags },
    { 'modal--has-posts': !!props.data.posts }
  );
  return (
    !!props.data && (
      <div className={resultsClasses}>
        {!!props.data.tags && (
          <TagList
            tags={props.data.tags}
            heading={window.IUCNORR_THEME_DATA.searchTagsHeading}
          />
        )}
        {!!props.data.persons && (
          <PersonList
            persons={props.data.persons}
            heading={window.IUCNORR_THEME_DATA.searchPersonHeading}
          />
        )}
        {!!props.data.posts && (
          <PostList
            posts={props.data.posts}
            heading={window.IUCNORR_THEME_DATA.searchPostHeading}
          />
        )}
      </div>
    )
  );
}

SearchResults.displayName = 'search/SearchResults';

SearchResults.propTypes = {
  data: searchResultsType,
};
