import React from 'react';
import PropTypes from 'prop-types';

export default class SearchInput extends React.Component {
  focus = () => {
    this.textInput.focus();
  };

  render() {
    const { onChange, value } = this.props;
    return (
      <div className="search-input-container search-header-input">
        <label className="visuallyhidden" htmlFor="search-modal-input">
          {window.IUCNORR_THEME_DATA.searchInputLabel}
        </label>
        <input
          id="search-modal-input"
          className="search-input"
          type="search"
          onChange={onChange}
          value={value}
          ref={input => {
            this.textInput = input;
          }}
          placeholder={window.IUCNORR_THEME_DATA.searchInputHint}
        />
        <div className="search-input-logo" />
      </div>
    );
  }
}
SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
