import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const rootNode = document.getElementById('search-trigger-root');

export default class SearchTrigger extends React.Component {
  focus = () => {
    this.buttonElement.focus();
  };

  render() {
    const { onClick } = this.props;
    return ReactDOM.createPortal(
      <button
        type="button"
        className="search-togglers search-button"
        onClick={onClick}
        ref={c => {
          this.buttonElement = c;
        }}
      >
        <img
          className="search-spyglass"
          src={window.IUCNORR_THEME_DATA.searchIconWhite}
          alt=""
        />
        <span className="search-text">
          {window.IUCNORR_THEME_DATA.searchToggleText}
        </span>
      </button>,
      rootNode
    );
  }
}
SearchTrigger.displayName = 'SearchTrigger';
SearchTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
};
