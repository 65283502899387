import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import SearchInput from './SearchInput';
import SearchHeader from './SearchHeader';
import SearchClose from './SearchClose';
import SearchTrigger from './SearchTrigger';
import SearchModal from './SearchModal';
import SearchResults from './SearchResults';
import { getSearchResults, sendAnalyticsEvent } from './utils';

const debouncedSendAnalyticsEvent = debounce(sendAnalyticsEvent, 1500);
const windowWidth = window.outerWidth;
const hintText =
  windowWidth <= 800
    ? window.IUCNORR_THEME_DATA.mobileHint
    : window.IUCNORR_THEME_DATA.normalHint;

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      isActive: false,
      searchResults: null,
      searchError: null,
    };
  }

  sendRequest = async () => {
    try {
      const { searchTerm } = this.state;
      const tagLimit = windowWidth <= 800 ? 10 : 0;
      const returnData = await getSearchResults(searchTerm, tagLimit);

      if (returnData.error) {
        this.setState({ searchError: returnData.error });
      } else {
        // Send the search term and number of results to Analytics
        debouncedSendAnalyticsEvent({
          eventLabel: searchTerm.toLowerCase(),
          eventValue: returnData.length,
        });
        this.setState({
          searchError: null,
          searchResults: returnData.length <= 0 ? null : returnData,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  debouncedSendRequest = debounce(this.sendRequest, 300);

  throttledSendRequest = throttle(this.sendRequest, 300, { leading: false });

  handleSearchTriggerClick = () => {
    this.setState(
      {
        isActive: true,
      },
      () => {
        this.searchInput.focus();
      }
    );
  };

  handleModalClose = () => {
    this.setState(
      {
        isActive: false,
      },
      () => {
        this.searchTrigger.focus();
      }
    );
  };

  handleSearchChange = e => {
    const searchTerm = e.target.value;
    const callback =
      windowWidth <= 800
        ? this.debouncedSendRequest
        : this.throttledSendRequest;

    this.setState(
      {
        searchTerm,
      },
      () => {
        // Run search after state is set with a value. If not, cancel any
        // pending requests for previous values.
        if (searchTerm) {
          callback();
        } else {
          callback.cancel();
        }
      }
    );
  };

  render() {
    const { isActive, searchTerm, searchResults, searchError } = this.state;
    const rootClasses = classnames('search-root', {
      'search--has-results': !!searchResults,
      'search-modal-closed': !isActive,
      'search-modal-open': isActive,
    });

    return (
      <div className={rootClasses}>
        <SearchTrigger
          onClick={this.handleSearchTriggerClick}
          ref={c => {
            this.searchTrigger = c;
          }}
        />
        <SearchModal onClose={this.handleModalClose}>
          <SearchHeader logoUrl={window.IUCNORR_THEME_DATA.logoUrl}>
            <SearchInput
              value={searchTerm}
              onChange={this.handleSearchChange}
              ref={c => {
                this.searchInput = c;
              }}
            />
            <SearchClose
              text={window.IUCNORR_THEME_DATA.searchModalClose}
              onClick={this.handleModalClose}
            />
          </SearchHeader>
          {!!searchResults && <SearchResults data={searchResults} />}
          <div className="wrap-thin search-modal-hint-wrap" aria-live="polite">
            {!searchError && searchResults === null && (
              <p className="search-modal-pre search-modal-hint">
                {window.IUCNORR_THEME_DATA.searchPreInformation}
              </p>
            )}
            {!!searchError && (
              <p className="search-modal-hint search-modal-error">
                {searchError}
              </p>
            )}
            <p
              className="search-modal-hint"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: hintText }}
            />
          </div>
          <img
            className="search-corner-decoration"
            src={window.IUCNORR_THEME_DATA.decorBottomUrl}
            alt=""
          />
        </SearchModal>
      </div>
    );
  }
}
App.displayName = 'App';
